import { Box, Container, Divider, Link, makeStyles, Paper, Typography } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import rehypeReact from "rehype-react";

import BaseLayout from "components/BaseLayout";
import Footer from "components/Footer";
import Header from "components/Header";

import { baseUrl } from "../app/api";
import Summary from "../components/Summary";

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    a: Link,
  },
}).Compiler;

const useStyles = makeStyles({
  image: {
    height: 400,
    width: "100%",
    objectFit: "cover",
    backgroundColor: "#fff"
  },
  title: {
    fontSize: "2.25em",
    textAlign: "center",
    margin: "0em 1em 0.5em 1em",
    "@media (min-width:600px)": {
      fontSize: "3em",
    },
    "@media (min-width:960px)": {
      fontSize: "4.25em",
    },
  },
  byLine: {
    fontSize: "1.25em",
    "@media (min-width:600px)": {
      fontSize: "1.5em",
    },
  },
  body: {
    fontSize: "1.25em",
    "@media (min-width:600px)": {
      fontSize: "1.5em",
    },
    fontWeight: "300",
  },
});

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  const classes = useStyles();

  return (
    <BaseLayout
      title={post.frontmatter.title}
      description={post.excerpt}
      image={baseUrl.website + post.frontmatter.backgroundImage}
      pageType="article"
      indexable={true}
      publishedTime={post.frontmatter.isoDate}
      author={post.frontmatter.author}
      section={"blog"}
    >
      <Header />
      <Container maxWidth="lg">
        <Box>
          <Paper variant="outlined">
            <Box display="flex" flexDirection="column" alignItems="center" mb={0.25}>
              <img className={classes.image} src={post.frontmatter.backgroundImage} alt={post.frontmatter.title} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h2" component="h2" className={classes.title}>
                {post.frontmatter.title}
              </Typography>
              <Typography className={classes.byLine}>
                {post.frontmatter.author} / {post.frontmatter.date} / {post.fields.readingTime.text}
              </Typography>
            </Box>
            <Box p={4}>
              <Typography variant="body1" className={classes.body} component="div">
                {renderAst(post.htmlAst)}
              </Typography>
            </Box>
            <Box p={4}>
              <Typography variant="h3" component="h3">
                About The Author
              </Typography>
              <Divider />
              <Summary author={post.frontmatter.author} />
            </Box>
          </Paper>
        </Box>
      </Container>
      <Box p={4}>
        <Footer showImageDisclaimer/>
      </Box>
    </BaseLayout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      excerpt
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        author
        backgroundImage
        twitter
        path
        date(formatString: "MMMM DD, YYYY")
        isoDate: date(formatString: "YYYY-MM-DD")
        blog
        breadcrumbs {
          link
          title
        }
      }
    }
  }
`;
